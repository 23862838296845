@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@keyframes scroll-desktop {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-75%);
  }
}

@keyframes scroll-mobile {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-200%);
  }
}

.scroll-text {
  animation: scroll-desktop 8s linear infinite;
}

@media (max-width: 768px) {
  .scroll-text {
    animation: scroll-mobile 8s linear infinite;
  }
}

@keyframes color-change {
  0% {
    color: red;
  }
  25% {
    color: blue;
  }
  50% {
    color: green;
  }
  75% {
    color: orange;
  }
  100% {
    color: red;
  }
}

.animate-color-change {
  animation: color-change 5s infinite;
}
